export class AuthenticationData {

roles: string [];
exp: number;
jti: string;
lastName: string;
profileId: number;
profileName: string;
scope: string [];
email: string;

}
